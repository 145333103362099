/* istanbul ignore file */

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyIcon from '@mui/icons-material/Key'
import PasswordIcon from '@mui/icons-material/Password'
import ReplayIcon from '@mui/icons-material/Replay'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import type { AlertColor } from '@mui/material/Alert'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import type React from 'react'
import { lazy, Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDocumentTitle } from 'usehooks-ts'
import { useAuthenticationToken } from '../common/hooks/use-authentication-token'
import { changeUserPassword, regenerateToken, useMounted } from '../common/restAPI'
import type { LegacyUserInfo } from '../common/types/legacy-types'
import type { CreateUpdateResponseRawJsProtobuf } from '../common/types/raw-javascript-protobuf-types'
import { parseStatusMessage } from '../common/utils'
import { CodeBlock } from './code-block'
import { ListItemWithIcon } from './list-item-with-icon'

export type ViewAccountProps = {
  readonly userInfo: LegacyUserInfo
  readonly tab: string
  readonly showPasswordChange?: boolean
}

export function ViewAccount({ userInfo, tab, showPasswordChange }: ViewAccountProps) {
  const { token, setAuthenticationToken } = useAuthenticationToken()

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [newPasswordRepeated, setNewPasswordRepeated] = useState('')
  const [passwordChangeMessage, setPasswordChangeMessage] = useState('')
  const [passwordChangeMessageSeverity, setPasswordChangeMessageSeverity] = useState<AlertColor>('info')
  const [regenerateTokenMessage, setRegenerateTokenMessage] = useState('')
  const [activeTab, setActiveTab] = useState(tab)

  useDocumentTitle('Account Settings | AI Hub')

  const handlePasswordChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent page re-load
    event.preventDefault()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mountState, tearDownMounted] = useMounted()

    if (newPassword != newPasswordRepeated) {
      setPasswordChangeMessage('Passwords do not match. Please retype your new password.')
      setPasswordChangeMessageSeverity('error')
      setRegenerateTokenMessage('')
      return tearDownMounted
    }

    changeUserPassword(
      oldPassword,
      newPassword,
      mountState,
      () => {
        setPasswordChangeMessage('Successfully updated password.')
        setPasswordChangeMessageSeverity('info')
        setOldPassword('')
        setNewPassword('')
        setNewPasswordRepeated('')
        setRegenerateTokenMessage('')
      },
      (responsePb: CreateUpdateResponseRawJsProtobuf) => {
        setPasswordChangeMessage(responsePb.getStatus())
        setPasswordChangeMessageSeverity('error')
        setRegenerateTokenMessage('')
      },
    )

    return tearDownMounted
  }

  const handleRegenerateToken = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mountState, tearDownMounted] = useMounted()

    regenerateToken(
      mountState,
      (newToken: string) => {
        setAuthenticationToken(newToken)
        setPasswordChangeMessage('')
        setRegenerateTokenMessage('')
      },
      () => {
        setRegenerateTokenMessage('Failed to update API token.')
        setPasswordChangeMessage('')
      },
    )

    return tearDownMounted
  }

  const handleChangeOldPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value)
  }

  const handleChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value)
  }

  const handleChangeNewPasswordRepeated = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordRepeated(event.target.value)
  }

  const handleToggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }

  const PasswordChangeForm = () => {
    const PasswordStrengthBar = lazy(() => import('react-password-strength-bar'))
    return (
      <List sx={{ width: '100%', maxWidth: '720px' }}>
        <ListItemWithIcon icon={<PasswordIcon />}>
          <Box sx={{ maxWidth: '350px' }}>
            <form>
              <Box>
                <Grid container justifyContent="flex-start" spacing={2}>
                  <Grid item xs={11}>
                    <TextField
                      id="email"
                      type="email"
                      autoComplete="email"
                      value={userInfo.user?.getEmail()}
                      label="Email"
                      variant="outlined"
                      sx={{ mb: 1.2, width: 1 }}
                      style={{ display: 'none' }}
                    />
                    <TextField
                      id="old-password"
                      type="password"
                      autoComplete="current-password"
                      value={oldPassword}
                      onChange={handleChangeOldPassword}
                      label="Current password"
                      variant="outlined"
                      sx={{ mb: 1.2, width: 1 }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container justifyContent="flex-start" spacing={2}>
                  <Grid item xs={11}>
                    <TextField
                      id="new-password"
                      type={showNewPassword ? 'text' : 'password'}
                      autoComplete="off"
                      value={newPassword}
                      onChange={handleChangeNewPassword}
                      label="New password"
                      variant="outlined"
                      sx={{ mb: 1.2, width: 1 }}
                    />
                    <Suspense fallback={<div style={{ height: '33px' }} />}>
                      <PasswordStrengthBar
                        password={newPassword}
                        minLength={5}
                        onChangeScore={(score, feedback) => {
                          console.log(score, feedback)
                        }}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={showNewPassword ? 'Hide New Password' : 'Show New Password'}>
                      <IconButton onClick={handleToggleShowNewPassword} component="span" sx={{ mr: 1, mt: 1 }}>
                        {showNewPassword && <VisibilityIcon />}
                        {!showNewPassword && <VisibilityOffIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container justifyContent="flex-start" spacing={2}>
                  <Grid item xs={11}>
                    <TextField
                      id="new-password-repeated"
                      type={showNewPassword ? 'text' : 'password'}
                      autoComplete="off"
                      value={newPasswordRepeated}
                      onChange={handleChangeNewPasswordRepeated}
                      label="Confirm new password"
                      variant="outlined"
                      sx={{ mb: 1.2, width: 1 }}
                    />
                  </Grid>
                </Grid>
              </Box>
              {passwordChangeMessage && (
                <Alert severity={passwordChangeMessageSeverity} sx={{ mb: 1 }}>
                  {parseStatusMessage(passwordChangeMessage)}
                </Alert>
              )}
              <Box>
                <Button type="submit" onClick={handlePasswordChange} variant="contained">
                  Change password
                </Button>
              </Box>
            </form>
          </Box>
        </ListItemWithIcon>
      </List>
    )
  }

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab)

    // eslint-disable-next-line functional/no-let
    let url = '/account/'
    const extensions = new URLSearchParams()
    extensions.set('tab', newTab)

    const extensionString = extensions.toString()
    if (extensionString.length > 0) {
      url = `${url}?${extensionString}`
    }

    window.history.replaceState(undefined, '', url)
  }

  const tabStyle = { height: '50px' }

  const accountTab = (
    <div data-testid="viewaccount-main">
      <Accordion defaultExpanded id="detailPanel-account-token">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="detailPanel-content"
          id="detailPanel-account-token-header"
        >
          <Typography variant="h6">Getting Started</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ overflow: 'hidden' }} id="detailPanel-account-token-content">
          <List sx={{ width: '100%', maxWidth: '780px', pt: 0 }}>
            <ListItem sx={{ pt: 0, pb: 0 }}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item xs={11}>
                  <List sx={{ listStyle: 'decimal', ml: 2 }}>
                    <ListItem sx={{ display: 'list-item', pl: 1 }}>
                      <Typography sx={{ mb: 1 }}>
                        Set up your account using the API Token below by following{' '}
                        <a href="https://aihub.qualcomm.com/get-started">these instructions</a> to submit your first
                        model on Qualcomm AI Hub.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 1 }}>
                      Test it out by{' '}
                      <a href="https://aihub.qualcomm.com/get-started#postinstall">
                        running a model on a real device of your choice
                      </a>
                      .
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      {userInfo.user && (
        <Accordion defaultExpanded id="detailPanel-account-details">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="detailPanel-content"
            id="detailPanel-account-details-header"
          >
            <Typography variant="h6">Your Account</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ overflow: 'hidden' }} id="detailPanel-account-details-content">
            <List sx={{ width: '100%', maxWidth: '780px' }}>
              <ListItemWithIcon icon={<AlternateEmailIcon />} caption="E-mail">
                <Typography color="black">{userInfo.user.getEmail()}</Typography>
              </ListItemWithIcon>
            </List>
            <List sx={{ width: '100%', maxWidth: '780px', pb: 0 }}>
              <ListItemWithIcon icon={<KeyIcon />} caption="API Token">
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item xs={11}>
                    {regenerateTokenMessage && (
                      <Alert severity="error" sx={{ mb: 1 }}>
                        {parseStatusMessage(regenerateTokenMessage)}
                      </Alert>
                    )}
                    <CodeBlock>{token}</CodeBlock>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={'Generate new API Token'}>
                      <IconButton onClick={handleRegenerateToken} component="span">
                        <ReplayIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </ListItemWithIcon>
            </List>
            <List>
              <ListItemWithIcon icon={<AccountCircleIcon />} caption="MyAccount">
                <Typography>
                  <Link to="https://myaccount.qualcomm.com/profile" target="_blank">
                    View and Update your Profile Details
                  </Link>
                </Typography>
              </ListItemWithIcon>
            </List>
            {showPasswordChange && <PasswordChangeForm />}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  )

  const organizationTab = (
    <div data-testid="viewaccount-organization">
      {userInfo.organization && (
        <Accordion defaultExpanded id="detailPanel-organization">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="detailPanel-content"
            id="detailPanel-organization-header"
          >
            <Typography variant="h6">{userInfo.organization.getName()}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ overflow: 'hidden' }} id="detailPanel-organization-content">
            <TableContainer sx={{ maxWidth: 900 }} component={Paper}>
              <Table aria-label="organization members">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userInfo.organization
                    .getMembers()
                    .getUsersList()
                    .map((member) => (
                      <TableRow key={member.getEmail()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          {member.getFirstName()} {member.getLastName()}
                        </TableCell>
                        <TableCell>{member.getEmail()}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  )

  return (
    <div className="main">
      <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
        Settings
      </Typography>
      <Paper>
        <Box>
          <Tabs value={activeTab} aria-label="settings tabs" onChange={handleChangeTab}>
            <Tab label="Account" value="account" style={tabStyle} />
            <Tab label="Organization" value="organization" style={tabStyle} />
          </Tabs>
        </Box>
      </Paper>
      {activeTab == 'account' && accountTab}
      {activeTab == 'organization' && organizationTab}
    </div>
  )
}
