/* istanbul ignore file */

import type { SelectChangeEvent } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import type { Writable } from 'type-fest'
import Cookies from 'universal-cookie'
import { useDocumentTitle } from 'usehooks-ts'
import { getModels, useMounted } from '../common/restAPI'
import type { LegacyUserInfo } from '../common/types/legacy-types'
import type {
  FilteredOwner,
  PagedViewFilterParameters,
  PagedViewQueryParameters,
} from '../common/types/paged-view-filter-parameters'
import type { ModelListRawJsProtobuf, ModelRawJsProtobuf } from '../common/types/raw-javascript-protobuf-types'
import { modelTypeToString } from '../common/utils'
import { CreatorFilter } from './creator-filter'
import TablePaginationActions from './TablePaginationActions'

const cookies = new Cookies()
const rowsPerPageCookieName = 'rowsPerPage-ListModels'

/* istanbul ignore next */
export function ListModels(props: ListModelsProps) {
  const defaultRowsPerPage = 10
  const rowsPerPageFromCookie = Number.parseInt(cookies.get(rowsPerPageCookieName) as string)
  const currentRowsPerPage = Number.isNaN(rowsPerPageFromCookie) ? defaultRowsPerPage : rowsPerPageFromCookie

  const { userInfo, filteredOwner } = props

  const [modelList, setModelList] = useState<readonly ModelRawJsProtobuf[]>([])
  const [totalQueryCount, setTotalQueryCount] = useState(0)
  const [query, setQuery] = useState<PagedViewFilterParameters>({
    page: props.page ?? 0,
    rowsPerPage: props.rowsPerPage ?? currentRowsPerPage,
    filteredOwner: props.filteredOwner,
  })

  const updateQueryAndPageURL = useCallback((newQuery: PagedViewFilterParameters) => {
    setQuery(newQuery)
    updatePageURL(newQuery)
  }, [])

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      if (newPage != query.page) {
        updateQueryAndPageURL({ ...query, page: newPage })
      }
    },
    [query, updateQueryAndPageURL],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = Number.isNaN(Number.parseInt(event.target.value))
        ? currentRowsPerPage
        : Number.parseInt(event.target.value)

      if (query.rowsPerPage !== newRowsPerPage) {
        const queryUpdate: Partial<PagedViewFilterParameters> = {
          rowsPerPage: newRowsPerPage,
          page:
            totalQueryCount < query.page * newRowsPerPage ? Math.floor(totalQueryCount / newRowsPerPage) : query.page,
        }
        cookies.set(rowsPerPageCookieName, newRowsPerPage)
        updateQueryAndPageURL({ ...query, ...queryUpdate })
      }
    },
    [currentRowsPerPage, query, totalQueryCount, updateQueryAndPageURL],
  )

  const handleChangeCreator = useCallback(
    (event: SelectChangeEvent<FilteredOwner>) => {
      const owner = event.target.value as FilteredOwner
      if (query.filteredOwner !== owner) {
        // Reset the page too
        updateQueryAndPageURL({ ...query, page: 0, filteredOwner: owner })
      }
    },
    [query, updateQueryAndPageURL],
  )

  const updatePageURL = (newQuery: PagedViewFilterParameters) => {
    // eslint-disable-next-line functional/no-let
    let url = '/models/'
    const extensions = new URLSearchParams()
    if (newQuery.page >= 1) {
      // Only one-based in the URL
      extensions.set('page', (newQuery.page + 1).toString())
    }
    if (newQuery.rowsPerPage != defaultRowsPerPage) {
      extensions.set('rowsPerPage', newQuery.rowsPerPage.toString())
    }
    if (newQuery.filteredOwner) {
      extensions.set('ownerKind', newQuery.filteredOwner.kind)
      extensions.set('ownerName', newQuery.filteredOwner.owner)
    }

    const extensionsString = extensions.toString()
    if (extensionsString.length > 0) {
      url = `${url}?${extensionsString}`
    }

    window.history.replaceState(undefined, '', url)
  }

  useDocumentTitle('Models | AI Hub')

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mountState, tearDownMounted] = useMounted()
    const defaultParameters: Writable<PagedViewQueryParameters> = {}
    if (query.filteredOwner?.kind === 'user') {
      // eslint-disable-next-line functional/immutable-data
      defaultParameters.creator = query.filteredOwner.owner
    }

    getModels(
      query.page,
      query.rowsPerPage,
      mountState,
      (modelListPb: ModelListRawJsProtobuf) => {
        setModelList(modelListPb.getModelsList())
        setTotalQueryCount(modelListPb.getTotalQueryCount())
      },
      (error: unknown) => {
        console.log(error)
      },
      defaultParameters,
    )

    return tearDownMounted
  }, [query])

  const renderJobs = () => {
    const elements = modelList.map((item) => (
      <TableRow key={item.getModelId()}>
        <TableCell style={{ maxWidth: '25vw' }}>
          <Link
            to={`/models/${item.getModelId()}/`}
            style={{
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {item.getName()}
          </Link>
        </TableCell>
        <TableCell>
          <span className="idbox">{item.getModelId()}</span>
        </TableCell>
        <TableCell>{modelTypeToString(item.getModelType())}</TableCell>
        <TableCell>{item.getOwner().getEmail()}</TableCell>
        <TableCell>{item.getCreationTime().toDate().toLocaleString()}</TableCell>
      </TableRow>
    ))
    return (
      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>
                <CreatorFilter userInfo={userInfo} onChange={handleChangeCreator} filteredOwner={filteredOwner} />
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={7}
                count={totalQueryCount}
                rowsPerPage={query.rowsPerPage}
                page={query.page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: false,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
            <TableRow key="header">
              <TableCell>Model Name</TableCell>
              <TableCell>Model ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Creator</TableCell>
              <TableCell>Creation Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{elements}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div className="main" data-testid="listmodels-main">
      <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
        Models
      </Typography>
      {renderJobs()}
    </div>
  )
}

export type ListModelsProps = {
  readonly page?: number
  readonly rowsPerPage?: number
  readonly userInfo: LegacyUserInfo
  readonly filteredOwner?: FilteredOwner
}
