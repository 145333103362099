import InfoIcon from '@mui/icons-material/InfoOutlined'
import type { SxProps } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

export function InfoButton({ sx, title }: { readonly sx?: SxProps; readonly title: string }) {
  return (
    <Tooltip title={title}>
      <IconButton sx={{ color: '#aaa', ...sx }} aria-label="info" component="span">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
