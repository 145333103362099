/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* istanbul ignore file */

/* eslint-disable functional/immutable-data */
/* eslint-disable functional/no-let */

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { SparkLineChart } from '@mui/x-charts/SparkLineChart'
import PropTypes from 'prop-types'
import type { ProfileDetailRawJsProtobuf } from '../common/types/raw-javascript-protobuf-types'
import { displayInMegabytes, displayInMillis, displayRangePbInMegabytes } from '../common/utils'
import { theme } from '../theme'
import { DeprecatedMetricsWarning } from './deprecated-metrics-warning'
import { InfoButton } from './info-button'
import { ArrayMetric, Metric, NOT_AVAILABLE } from './metrics'
import { MissingMetricsWarning } from './missing-metrics-warning'

type DetailedMetricCategory = 'Compilation' | 'First App Load' | 'Subsequent App Load' | 'Inference'

type DetailedMetric = {
  readonly toolTip: string
  memory?: string
  times?: number[]
}

type DetailedMetrics = Record<DetailedMetricCategory, DetailedMetric>

export function JobDetailedMetrics({ profileInfo }: { readonly profileInfo: ProfileDetailRawJsProtobuf }) {
  const detailedMetrics: DetailedMetrics = {
    'Compilation': {
      toolTip:
        'The stage of compiling the supplied model. Metrics may be unavailable if the inference framework does not require it or on-device compilation is undesirable due to bugs in the framework.',
    },
    'First App Load': {
      toolTip: 'The stage of loading the app on a device for the first time.',
    },
    'Subsequent App Load': {
      toolTip: 'The stage of loading the app on a device after the first time.',
    },
    'Inference': {
      toolTip: 'The stage of feeding an input to the model to produce an output.',
    },
  }
  let showMissingMetricsWarning = false
  let showDeprecatedMetricsWarning = false
  let extraMemoryMetricClass
  let haveCompileMetrics = true

  // Major version 1
  if (profileInfo.getMajorVersion() === 1) {
    if (profileInfo.getMinorVersion() >= 1) {
      // Added in Version 1.1
      const compileMemory = profileInfo.getCompileMemory()
      haveCompileMetrics = compileMemory?.getIncrease() !== undefined && compileMemory.getPeak() !== undefined
      if (haveCompileMetrics) {
        detailedMetrics.Compilation.memory = displayRangePbInMegabytes(compileMemory!.getPeak()!)
      }
      detailedMetrics['First App Load'].memory = displayRangePbInMegabytes(profileInfo.getColdLoadMemory()!.getPeak()!)
      detailedMetrics['Subsequent App Load'].memory = displayRangePbInMegabytes(
        profileInfo.getWarmLoadMemory()!.getPeak()!,
      )
      detailedMetrics.Inference.memory = displayRangePbInMegabytes(profileInfo.getExecutionMemory()!.getPeak()!)
    } else {
      // Added in Version 1.0
      detailedMetrics.Compilation.memory = displayInMegabytes(profileInfo.getAfterCompilePeakMemory())
      detailedMetrics['First App Load'].memory = displayInMegabytes(profileInfo.getAfterColdLoadPeakMemory())
      detailedMetrics['Subsequent App Load'].memory = displayInMegabytes(profileInfo.getAfterWarmLoadPeakMemory())
      detailedMetrics.Inference.memory = displayInMegabytes(profileInfo.getAfterExecutionPeakMemory())
      showDeprecatedMetricsWarning = true
      extraMemoryMetricClass = 'deprecated-metric'
    }
    if (haveCompileMetrics) {
      detailedMetrics.Compilation.times = [profileInfo.getCompileTime()]
    }
    detailedMetrics['First App Load'].times = [profileInfo.getColdLoadTime()]
    detailedMetrics['Subsequent App Load'].times = [profileInfo.getWarmLoadTime()]
    const times = profileInfo.getAllExecutionTimesList()
    const time = profileInfo.getExecutionTime()
    if (times) {
      detailedMetrics.Inference.times = [...times]
    } else if (time) {
      detailedMetrics.Inference.times = [time]
    } else {
      detailedMetrics.Inference.times = []
    }
  } else {
    // Version 0
    detailedMetrics.Compilation.memory = displayInMegabytes(profileInfo.getPeakMemoryUsage())
    detailedMetrics['Subsequent App Load'].times = [profileInfo.getLoadTime()]
    detailedMetrics.Inference.times = [profileInfo.getExecutionTime()]
    showMissingMetricsWarning = true
  }

  let memoryMetricClass = 'right-aligned-metric'
  if (extraMemoryMetricClass != undefined) {
    memoryMetricClass += ' ' + extraMemoryMetricClass
  }

  const DetailedMetricsRow = (props: { readonly category: DetailedMetricCategory }) => {
    const times = detailedMetrics[props.category].times ?? []
    const hasMultipleSamples = times.length >= 2
    return (
      <TableRow>
        <TableCell className="detailedMetricsCategory">
          {props.category}
          <InfoButton sx={{ mt: '-10px' }} title={detailedMetrics[props.category].toolTip} />
        </TableCell>
        <TableCell style={{ textAlign: 'left' }}>
          <ArrayMetric className="right-aligned-metric" values={times} valueFormatter={displayInMillis} />
        </TableCell>
        <TableCell style={{ textAlign: 'left' }} sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
          {hasMultipleSamples && (
            <SparkLineChart
              data={times}
              height={60}
              width={150}
              sx={{ border: '1px solid #eee', margin: '0px' }}
              colors={[theme.palette.primary.main]}
              showHighlight={true}
              showTooltip={true}
              valueFormatter={(value: number | null) => (value === null ? 'null' : displayInMillis(value))}
              xAxis={{
                scaleType: 'point',
                data: Array.from({ length: times.length }, (v, k) => `Iteration ${(k + 1).toString()}`),
              }}
            />
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'left' }}>
          <Metric
            className={memoryMetricClass}
            value={
              detailedMetrics[props.category].memory === undefined
                ? NOT_AVAILABLE
                : detailedMetrics[props.category].memory!
            }
          />
        </TableCell>
      </TableRow>
    )
  }
  DetailedMetricsRow.propTypes = {
    category: PropTypes.string.isRequired,
  }

  return (
    <Accordion defaultExpanded data-testid="viewjobdetailedmetrics">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="profilePanel-content"
        id="detailedMetricsPanel-header"
      >
        <Typography>
          Detailed Metrics
          {showMissingMetricsWarning && <MissingMetricsWarning />}
          {showDeprecatedMetricsWarning && <DeprecatedMetricsWarning />}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ overflow: 'hidden' }} id="detailedMetricsPanel-header">
        <TableContainer>
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell>Stage</TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  <span className="right-aligned-metric">Time</span>
                </TableCell>
                <TableCell style={{ textAlign: 'left' }} sx={{ display: { xs: 'none', sm: 'table-cell' } }}></TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  <span className="right-aligned-metric">Memory</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="detailed-metrics">
              {haveCompileMetrics && <DetailedMetricsRow category={'Compilation'} />}
              <DetailedMetricsRow category={'First App Load'} />
              <DetailedMetricsRow category={'Subsequent App Load'} />
              <DetailedMetricsRow category={'Inference'} />
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}
