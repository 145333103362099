/* istanbul ignore file */
/* eslint-disable functional/immutable-data */
/* eslint-disable functional/no-let */

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { lighten, styled } from '@mui/material/styles'
import type { AxiosRequestConfig } from 'axios'
import { ComputeUnit, JobState, JobType, ModelMetadataKey, ModelType, TensorDtype } from '../generated/api/public_api'
import type { DispatchInfo, LayerRow } from './types/legacy-types'
import type {
  ProfileDetailRawJsProtobuf,
  RangeRawJsProtobuf,
  TensorTypeRawJsProtobuf,
} from './types/raw-javascript-protobuf-types'

// Comma-separate running job states that correspond to "running" jobs
export const RUNNING_JOB_STATES = [
  JobState.JOB_STATE_CREATED,
  JobState.JOB_STATE_OPTIMIZING_MODEL,
  JobState.JOB_STATE_QUANTIZING_MODEL,
  JobState.JOB_STATE_LINKING_MODELS,
  JobState.JOB_STATE_PROVISIONING_DEVICE,
  JobState.JOB_STATE_MEASURING_PERFORMANCE,
  JobState.JOB_STATE_RUNNING_INFERENCE,
].join(',')

// Check if running in development mode
// eslint-disable-next-line unicorn/prevent-abbreviations
export function isDev(): boolean {
  return process.env.NODE_ENV === 'development'
}

export function computeUnitToString(computeUnit: ComputeUnit): string {
  switch (computeUnit) {
    case ComputeUnit.COMPUTE_UNIT_CPU: {
      return 'CPU'
    }
    case ComputeUnit.COMPUTE_UNIT_GPU: {
      return 'GPU'
    }
    case ComputeUnit.COMPUTE_UNIT_NPU: {
      return 'NPU'
    }
    case ComputeUnit.COMPUTE_UNIT_UNSPECIFIED: {
      return '(Unknown)'
    }
    case ComputeUnit.UNRECOGNIZED: {
      return 'UNRECOGNIZED'
    }
  }
}

export function computeUnitToStringIfValid(computeUnit: ComputeUnit): string {
  return computeUnit === ComputeUnit.COMPUTE_UNIT_UNSPECIFIED ? '' : computeUnitToString(computeUnit)
}

const NPU_COLOR = '#641c87'
const GPU_COLOR = '#17991b'
const CPU_COLOR = '#1c7fbd'
const UNKNOWN_COLOR = '#555555'

// Variant colors (used for specific delegates)
const NPU_COLOR_VAR1 = '#7d2252'
const NPU_COLOR_VAR2 = '#b941c4'
const NPU_COLOR_VAR3 = '#6e59b7'
const GPU_COLOR_VAR1 = '#377819'
const GPU_COLOR_VAR2 = '#739c19'
const CPU_COLOR_VAR1 = '#48a6b0'

export function computeUnitToColor(computeUnit: ComputeUnit): string {
  switch (computeUnit) {
    case ComputeUnit.COMPUTE_UNIT_CPU: {
      return CPU_COLOR
    }
    case ComputeUnit.COMPUTE_UNIT_GPU: {
      return GPU_COLOR
    }
    case ComputeUnit.COMPUTE_UNIT_NPU: {
      return NPU_COLOR
    }
    default: {
      return UNKNOWN_COLOR
    }
  }
}

export function dispatchInfoToColor(dispatchInfo: DispatchInfo | undefined): string {
  if (!dispatchInfo) {
    return UNKNOWN_COLOR
  }

  const { computeUnit, delegateName, delegateExtraInfo } = dispatchInfo
  const name = delegateName.toLowerCase()
  const extra = delegateExtraInfo.toLowerCase()

  switch (computeUnit) {
    case ComputeUnit.COMPUTE_UNIT_CPU: {
      switch (true) {
        case name == 'xnnpack': {
          return CPU_COLOR_VAR1
        }
        default: {
          return CPU_COLOR
        }
      }
    }
    case ComputeUnit.COMPUTE_UNIT_GPU: {
      switch (true) {
        case name == 'nnapi': {
          return GPU_COLOR_VAR1
        }
        case name == 'gpuv2' && extra == 'opengl': {
          return GPU_COLOR_VAR2
        }
        // eslint-disable-next-line unicorn/no-useless-switch-case
        case name == 'gpuv2' && extra == 'opencl':
        default: {
          return GPU_COLOR
        }
      }
    }
    case ComputeUnit.COMPUTE_UNIT_NPU: {
      switch (true) {
        case name == 'nnapi' && extra == 'qti-hta': {
          return NPU_COLOR_VAR1
        }
        case name == 'nnapi' && extra == 'google-edgetpu': {
          return NPU_COLOR_VAR2
        }
        case name == 'hexagon': {
          return NPU_COLOR_VAR3
        }
        // eslint-disable-next-line unicorn/no-useless-switch-case
        case name == 'nnapi' && extra == 'qti-dsp':
        default: {
          return NPU_COLOR
        }
      }
    }
    default: {
      return UNKNOWN_COLOR
    }
  }
}

export function pluralize(number: number, singularForm: string, pluralForm: string): string {
  return number === 1 ? singularForm : pluralForm
}

export function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function allCapsToUpperCamelCase(s: string): string {
  // SIGN_BIT => SignBit
  const words = s.split('_')
  const upperWords = words.map((word) => `${[...word][0].toUpperCase()}${word.slice(1).toLowerCase()}`)

  return upperWords.join('')
}

export const HeaderPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1.2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '3.6em',
  overflow: 'hidden',
}))

export const DefaultPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

export function parseStatusMessage(message: string): readonly React.JSX.Element[] {
  return message.split('\n').map((v, index) => {
    return <Typography key={index}>{v}</Typography>
  })
}

export function typeToString(type: TensorDtype): string {
  switch (type) {
    case TensorDtype.TENSOR_DTYPE_FLOAT32: {
      return 'float32'
    }
    case TensorDtype.TENSOR_DTYPE_FLOAT16: {
      return 'float16'
    }
    case TensorDtype.TENSOR_DTYPE_INT32: {
      return 'int32'
    }
    case TensorDtype.TENSOR_DTYPE_INT16: {
      return 'int16'
    }
    case TensorDtype.TENSOR_DTYPE_UINT16: {
      return 'uint16'
    }
    case TensorDtype.TENSOR_DTYPE_INT8: {
      return 'int8'
    }
    case TensorDtype.TENSOR_DTYPE_UINT8: {
      return 'uint8'
    }
    case TensorDtype.TENSOR_DTYPE_INT64: {
      return 'int64'
    }
    default: {
      return ''
    }
  }
}

export function jobTypeToString(type: JobType): string | undefined {
  switch (type) {
    case JobType.JOB_TYPE_COMPILE: {
      return 'compile'
    }
    case JobType.JOB_TYPE_PROFILE: {
      return 'profile'
    }
    case JobType.JOB_TYPE_INFERENCE: {
      return 'inference'
    }
    case JobType.JOB_TYPE_QUANTIZE: {
      return 'quantize'
    }
    case JobType.JOB_TYPE_LINK: {
      return 'link'
    }
    case JobType.JOB_TYPE_UNSPECIFIED:
    case JobType.UNRECOGNIZED: {
      return undefined
    }
  }
}

export function modelMetadataKeyToString(key: ModelMetadataKey): string {
  switch (key) {
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND: {
      return 'QNN_CONTEXT_BIN_BACKEND'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES: {
      return 'QNN_CONTEXT_BIN_GRAPH_NAMES'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION: {
      return 'QNN_CONTEXT_BIN_HEXAGON_VERSION'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL: {
      return 'QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL: {
      return 'QNN_CONTEXT_BIN_SOC_MODEL'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM: {
      return 'QNN_CONTEXT_BIN_VTCM'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_SDK_VARIANT: {
      return 'QNN_SDK_VARIANT'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_SDK_VERSION: {
      return 'QNN_SDK_VERSION'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_QAIRT_SDK_VERSION: {
      return 'QAIRT_SDK_VERSION'
    }
    case ModelMetadataKey.MODEL_METADATA_KEY_UNSPECIFIED:
    case ModelMetadataKey.UNRECOGNIZED: {
      return '(Unknown)'
    }
  }
}

export function formatSpec(spec: TensorTypeRawJsProtobuf): string {
  const shapeArray = spec.getShapeList()
  const type = typeToString(spec.getDtype())
  return shapeArray.length === 1 ? `${type}[${shapeArray[0].toString()}]` : `${type}[${shapeArray.join(', ')}]`
}

export function modelTypeToString(modelType: ModelType): string {
  switch (modelType) {
    case ModelType.MODEL_TYPE_TORCHSCRIPT:
    case ModelType.MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT: {
      return 'TorchScript'
    }
    case ModelType.MODEL_TYPE_MLMODEL: {
      return 'Core ML'
    }
    case ModelType.MODEL_TYPE_TFLITE: {
      return 'TensorFlow Lite'
    }
    case ModelType.MODEL_TYPE_MLMODELC: {
      return 'Compiled Core ML'
    }
    case ModelType.MODEL_TYPE_ONNX: {
      return 'ONNX'
    }
    case ModelType.MODEL_TYPE_ORT: {
      return 'ONNX Runtime'
    }
    case ModelType.MODEL_TYPE_MLPACKAGE: {
      return 'Core ML Package'
    }
    case ModelType.MODEL_TYPE_QNN_LIB_AARCH64_ANDROID: {
      return 'QNN Model Library for AArch64 Android'
    }
    case ModelType.MODEL_TYPE_QNN_LIB_X86_64_LINUX: {
      return 'QNN Model Library for x86-64 Linux'
    }
    case ModelType.MODEL_TYPE_QNN_CONTEXT_BINARY: {
      return 'QNN Context Binary'
    }
    case ModelType.MODEL_TYPE_AIMET_ONNX: {
      return 'AIMET ONNX Package'
    }
    case ModelType.MODEL_TYPE_AIMET_PT: {
      return 'AIMET Torchscript Package'
    }
    case ModelType.MODEL_TYPE_TETRART: {
      return 'Unknown'
    }
    case ModelType.MODEL_TYPE_UNSPECIFIED:
    case ModelType.UNRECOGNIZED: {
      return 'Unknown'
    }
  }
}

export function jobStateToString(jobState: JobState, includeInProgress = false) {
  switch (jobState) {
    case JobState.JOB_STATE_DONE: {
      return 'Results Ready'
    }
    case JobState.JOB_STATE_FAILED: {
      return 'Failed'
    }
    case JobState.JOB_STATE_CREATED: {
      return includeInProgress ? 'In Progress: Created' : 'Created'
    }
    case JobState.JOB_STATE_OPTIMIZING_MODEL: {
      return includeInProgress ? 'In Progress: Optimizing Model' : 'Optimizing Model'
    }
    case JobState.JOB_STATE_QUANTIZING_MODEL: {
      return includeInProgress ? 'In Progress: Quantizing Model' : 'Quantizing Model'
    }
    case JobState.JOB_STATE_LINKING_MODELS: {
      return includeInProgress ? 'In Progress: Linking Model' : 'Linking Model'
    }
    case JobState.JOB_STATE_PROVISIONING_DEVICE: {
      return includeInProgress ? 'In Progress: Provisioning Device' : 'Provisioning Device'
    }
    case JobState.JOB_STATE_MEASURING_PERFORMANCE: {
      return includeInProgress ? 'In Progress: Measuring Performance' : 'Measuring Performance'
    }
    case JobState.JOB_STATE_RUNNING_INFERENCE: {
      return includeInProgress ? 'In Progress: Running Inference' : 'Running Inference'
    }
    case JobState.JOB_STATE_UNSPECIFIED:
    case JobState.UNRECOGNIZED: {
      return 'Unknown'
    }
  }
}

export function apiURL(relativePath: string, params?: string): string {
  let queryString = ''
  if (params) {
    const searchParams = new URLSearchParams(params)
    queryString = '?' + searchParams.toString()
  }

  // Should not have trailing slash
  return '/api/v1/' + relativePath + queryString
}

// To fix a Safari issue, we use X-Auth-Token in production and then let NGINX
// forward it to Authorization. Since NGINX is not running in dev, we need to
// use the original Authorization token directly there.
function tokenHeaderKey(): string {
  return isDev() ? 'Authorization' : 'X-Auth-Token'
}

// Authentication header
export function authOptions(options: Readonly<AxiosRequestConfig>): Readonly<AxiosRequestConfig> {
  const headers = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    [tokenHeaderKey()]: `Token ${localStorage.getItem('token')!}`,
  }

  const finalOptions = {
    ...options,
    headers,
  }

  return finalOptions
}

// Authentication header for Protobuf messages
export function authProtobufOptions(options: Readonly<Record<string, unknown>>): Readonly<AxiosRequestConfig> {
  const coreOptions = authOptions(options)
  const finalOptions: AxiosRequestConfig = {
    ...coreOptions,
    responseType: 'arraybuffer',
    headers: { ...coreOptions.headers, 'Content-Type': 'application/x-protobuf' },
  }
  return finalOptions
}

// Converts supplied time in microseconds to displayable time in milliseconds.
export function displayInMillis(time: number): string {
  // \u00A0 is a nbsp;
  return `${(time / 1000).toFixed(1)}\u00A0ms`
}

// Display PSNR metric in a friendly way.
export function displayPsnr(psnr: number): string {
  if (psnr < 0) {
    return `< 0.0`
  }
  if (psnr >= 100) {
    return `> 100`
  }
  return psnr.toFixed(1).toString() + ' dB'
}

// Converts supplied memory usage in bytes to displayable memory in MB.
export function displayInMegabytes(bytes: number): string {
  // \u00A0 is a nbsp;
  return `${(bytes / 1024 / 1024).toFixed(1)}\u00A0MB`
}

export function displayRangeInMegabytes(lowerBytes: number, upperBytes: number): string {
  const lowerMB = lowerBytes / 1024 / 1024
  const upperMB = upperBytes / 1024 / 1024

  const decimals = upperMB <= 1 ? 1 : 0

  const lowerMBstr = lowerMB.toFixed(decimals)
  const upperMBstr = upperMB.toFixed(decimals)

  const nbsp = '\u00A0'

  return lowerMBstr === upperMBstr ? `${lowerMBstr}${nbsp}MB` : `${lowerMBstr}${nbsp}-${nbsp}${upperMBstr}${nbsp}MB`
}

export function displayRangePbInMegabytes(range: RangeRawJsProtobuf): string {
  return displayRangeInMegabytes(range.getLower(), range.getUpper())
}

function makeLayerRow(
  name: string,
  typeName: string,
  computeUnit: ComputeUnit,
  delegateName: string,
  placementRowSpan: number,
  delegateOps: readonly string[],
  execTime: number,
  execCycles: number,
  execRowSpan: number,
): LayerRow {
  const computeUnitName = computeUnitToString(computeUnit)
  const placement =
    delegateName.length > 0 && computeUnitName !== delegateName
      ? `${computeUnitName} (${delegateName})`
      : computeUnitName

  return {
    name,
    typeName: capitalizeFirstLetter(typeName),
    placement,
    placementRowSpan,
    delegateOps,
    execTime,
    execCycles,
    execRowSpan,
    sparklineWidth: 0,
    sparklineColor: computeUnitToColor(computeUnit),
  }
}

export function getLayersTableData(profileInfo: ProfileDetailRawJsProtobuf): readonly LayerRow[] {
  const layersTableData = []

  for (const segment of profileInfo.getSegmentDetailsList()) {
    const segmentLayers = profileInfo.getLayerDetailsList().filter((layer) => layer.getSegmentId() === segment.getId())

    let isFirstRow = true
    let omitPlacementCell = false
    let omitExecTimeCell = false

    // determine if this segment has per-layer timing to show.
    const segmentHasPerLayerTimes = segmentLayers.some((layer) => layer.getExecutionTime() > 0)
    const segmentHasPerLayerCycles = segmentLayers.some((layer) => layer.getExecutionCycles() > 0)

    for (const layer of segmentLayers) {
      let execTime = layer.getExecutionTime()
      if (!segmentHasPerLayerTimes) {
        execTime = segment.getExecutionTime()
      }

      // start with the assumption that every cell will span exactly 1 row (the default).
      let placementRowSpan = 1
      let execTimeRowSpan = 1

      if (isFirstRow) {
        // if we're processing the first row of the segment, we need to determine the row spans
        // for the placement and execution time columns.

        // placement will always be the length of the segment
        placementRowSpan = segmentLayers.length
        omitPlacementCell = placementRowSpan > 1

        // exec time depends on whether it is a per-layer or per-segment time
        execTimeRowSpan = segmentHasPerLayerTimes || segmentHasPerLayerCycles ? 1 : segmentLayers.length
        omitExecTimeCell = execTimeRowSpan > 1

        isFirstRow = false
      } else {
        // for all other rows, we use the previously set values for the segment to determine
        // whether to avoid omitting cells because of a previous row span. we use a value of -1
        // to indicate this.
        if (omitPlacementCell) {
          placementRowSpan = -1
        }

        if (omitExecTimeCell) {
          execTimeRowSpan = -1
        }
      }

      layersTableData.push(
        makeLayerRow(
          layer.getName(),
          layer.getLayerTypeName(),
          segment.getComputeUnit(),
          segment.getDelegateName(),
          placementRowSpan,
          layer.getDelegateReportedOpsList(),
          execTime,
          segmentHasPerLayerCycles ? layer.getExecutionCycles() : ('' as unknown as number),
          execTimeRowSpan,
        ),
      )
    }
  }

  // handle data without segments
  const segmentLayers = profileInfo.getLayerDetailsList().filter((layer) => layer.getSegmentId().length === 0)
  for (const layer of segmentLayers) {
    layersTableData.push(
      makeLayerRow(
        layer.getName(),
        layer.getLayerTypeName(),
        layer.getComputeUnit(),
        layer.getDelegateName(),
        1,
        layer.getDelegateReportedOpsList(),
        layer.getExecutionTime(),
        layer.getExecutionCycles() || ('' as unknown as number),
        1,
      ),
    )
  }

  // Which field should we use to calculate the spark line?
  // Use (wall clock) time unless we only have cycles.
  let timingField: keyof LayerRow = 'execTime'
  if (layersTableData.filter((layer) => layer.execTime > 0).length === 0) {
    timingField = 'execCycles'
  }

  let minTime = Number.MAX_SAFE_INTEGER
  let maxTime = 0
  for (const row of layersTableData) {
    if (row.execRowSpan >= 1) {
      minTime = Math.min(minTime, row[timingField])
      maxTime = Math.max(maxTime, row[timingField])
    }
  }

  if (maxTime - minTime > 0) {
    for (const row of layersTableData) {
      if (row.execRowSpan >= 0) {
        const fraction = (row[timingField] - minTime) / (maxTime - minTime)
        row.sparklineWidth = fraction * 100
        row.sparklineColor = lighten(row.sparklineColor, 1 - fraction)
      }
    }
  }

  return layersTableData
}

export function median(values: readonly number[]): number | undefined {
  if (values.length === 0) {
    return
  }

  // Sort values (make copy to ensure original is not sorted)
  const sorted_values = [...values].sort()

  const mid_index = Math.floor(sorted_values.length / 2)
  return sorted_values.length % 2 === 1
    ? sorted_values[mid_index]
    : (sorted_values[mid_index - 1] + sorted_values[mid_index]) / 2
}
